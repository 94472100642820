<template>
  <b-card-code title="Ajouter un credit">
    <b-card-text>
      <span>Choisir un client :</span>
    </b-card-text>
    <v-select
        v-model="credit.customer"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="customers"
        label="company_name"
        input-id="invoice-data-client"
        :clearable="false"
    />
    <b-card-text class="mt-1">
      <span>Ajouter un credit :</span>
    </b-card-text>
    <b-form-group
        label="Credit"
    >
        <b-form-input
            v-model="credit.amount"
            placeholder="Credit"
            type="text"
        />
    </b-form-group>
    <b-button
        variant="primary"
        type="submit"
        @click.prevent="addCredit"
    >
        Enregistrer
    </b-button>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'


export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      customers: [],
      
      credit: {
        customer: null,
        amount: null,
      },

    }
  },
  created() {
    this.getCustomers()
  },
  methods: {
    async getCustomers() {
      const { data } = await axiosIns.get('/customers/')
      this.customers = data
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

    async addCredit() {
      try {
        this.credit.customer = this.credit.customer.id
        await axiosIns.post('/credits/create/', this.credit)
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Credit ajouté avec succès')
        }, 1000)
        this.$router.push('/credits/')
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', error.toString())
        }, 1000)
      }
    },
   
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
